import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import MyTickets from './account/MyTickets';
import MyOrders from './account/MyOrders';
import Profile from './account/Profile';
import AllTickets from './account/AllTickets';


export default function MyAccount({currentUser, project}) {
  return (
    <div className="container">  
      <h1>Minha Conta</h1>
      <Tabs>
        <Tabs.Item tab="Títulos Ativos" key="1">
          <MyTickets currentUser={currentUser} project={project} />
        </Tabs.Item>
        <Tabs.Item tab="Histórico" key="2">
          <AllTickets currentUser={currentUser} project={project} />
        </Tabs.Item>
        <Tabs.Item tab="Pedidos" key="3">
          <MyOrders currentUser={currentUser} project={project} />
        </Tabs.Item>
        <Tabs.Item tab="Meus Dados" key="4">
          <Profile currentUser={currentUser} project={project} />
        </Tabs.Item>
      </Tabs>
    </div>
  );
}