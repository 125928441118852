import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import '../theme/styles/react.scss'
import { Layout, ConfigProvider } from 'antd';
import brLocale from 'antd/lib/locale/pt_BR';
import ScrollToTop from '../components/ScrollToTop.js';
import App from './App';

const validateMessages = {
  required: "'${label}' está em branco",
};

export default function AppWrapper({currentUser, currentAffiliate, flash, site_texts, cart, project}) {
  return (
    <ConfigProvider
      locale={brLocale}
      form={{validateMessages}}
      renderEmpty={() => "Nenhum resultado encontrado"}
      theme={{
        token: {
          colorBgSpotlight: '#253875',
          colorBorder: '#C8C8C8',
          colorBgContainer: '#E8ECFD',
          borderRadius: 10,
          colorPrimary: '#F6EB1C',
          controlHeight: 42,
          fontFamily: "Montserrat, Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",
        },
        components: {
          Menu: {
            itemColor: 'white',
            itemHoverColor: 'white',
            horizontalItemSelectedColor: 'white',
            itemBg: '#253875',
            activeBarHeight: '0px',
          },
          Tabs: {
            itemHoverColor: '#253875',
            itemActiveColor: '#253875',
            itemSelectedColor: '#253875',
            inkBarColor: '#253875',
          },
          Layout: {
            headerBg: '#253875',
            bodyBg: 'white',
            footerBg: '#253875',
            footerPadding: '10px 5px'
          },
          Form: {
            labelColor: '#3B0C9F'
          },
          Input: {
            paddingBlock: 8,
            activeBorderColor: '#253875',
            hoverBorderColor: '#253875',
            activeShadow: '0 0 0 2px rgba(5, 145, 255, 0.1)'
          },
          Button: {
            // defaultActiveBg: '',
            defaultBg: '#253875',
            defaultColor: 'white',
            defaultHoverColor: 'white',
            defaultHoverBg: '#253875',
            defaultActiveBg: '#253875',
            defaultHoverBorderColor: 'black',
            defaultActiveColor: 'white',
            primaryShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            primaryColor: '#253875'
          },
          Steps: {
            navArrowColor: 'red',

          }
        },
      }}
    >
      <Router>
        <ScrollToTop />
        <App currentUser={currentUser} currentAffiliate={currentAffiliate} flash={flash} site_texts={site_texts} cart={cart} project={project} />
      </Router>
    </ConfigProvider>
  );
}
