import React from 'react';
import { lpad } from '../single-page/utils/index.js';
import moment from 'moment';
import TrophyIcon from '../images/trophy.svg'

export default function Ticket({ticket, showCampaign = true, showTime = true}) {
  return (
    <div className={`rounded_square ticket_item ${ticket.winners.length > 0 ? 'gold' : ''}`}>
      {
        ticket.winners.length > 0 &&
          <img src={TrophyIcon} className="trophy" />
      }
      {
        showCampaign &&
          <h3>{ticket.campaign.title}</h3>
      }
      <p>
        <b>Título: </b>{lpad(ticket.number, ticket.campaign.digits)}
      </p>
      {
        ticket.campaign.bingo &&
          <p className="center">
            {
              ticket.numbers.map(number => (
                <span key={number} onClick={() => checkOrUncheckNumber(number)} className="bingo_ball">{lpad(number, 2)} </span>
              ))
            }
          </p>
      }
      {
        showTime &&
          <p className="center">({moment(ticket.purchase_order_date).format('DD/MM/YYYY HH:mm:ss')})</p>
      }
    </div>
  );
}