import React, { useState, useEffect } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import moment from 'moment';

export default function OrderStatus({order}) {
  if (!order?.user_status) return (<div>Pedido inválido</div>);

  function iconForStatus(status) {
    if (status == null)
      return null;
    if (['paid', 'completed'].includes(status))
      return <CheckCircleOutlined style={{ fontSize: '140px', color: '#253875' }} />
    if (['paid_after_time_limit', 'expired', 'error', 'canceled'].includes(status))
      return <CloseCircleOutlined style={{ fontSize: '140px', color: '#253875' }} />
    if (['created', 'waiting_payment'].includes(status))
      return <ClockCircleOutlined style={{ fontSize: '140px', color: '#253875' }} />
  }

  return (
    <div id="order_status">
      <h1>{order.user_status}</h1>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {
            iconForStatus(order?.status)
          }
        </Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <h2 className="purple">
            {order?.user_status_message}
          </h2>
          <p>
            <b>Data/Horário:</b> {moment(order?.qr_code_received_at).format('DD/MM/YY [às] HH:mm')}
            <br/>
            <b>Comprador:</b> {order?.customer?.name}
          </p>
        </Col>
      </Row>
    </div>
  );
}