import React, { useState, useEffect } from 'react';
import CartItems from './shopping_cart/CartItems';
import CartDetails from './shopping_cart/CartDetails';
import axios from 'axios';
import Cookie from "js-cookie";
import { Row, Col, Spin, Button } from 'antd';
import { CartContext } from '../App';
import { useHistory } from 'react-router-dom';
import CampaignSmallBuy from '../../components/CampaignSmallBuy';
import { Link } from 'react-router-dom';
import SadIcon from '../../images/sad.svg';
import PurchaseOrderSteps from '../../components/PurchaseOrderSteps';

import { toast } from 'react-toastify';


export default function ShoppingCart({currentUser, isMobile, project}) {
  const [cart, setCart] = useState({});
  const [loading, setLoading] = useState(true);
  const { cartCount, setCartCount } = React.useContext(CartContext);
  const [defaultCampaign, setDefaultCampaign] = useState(null);
  const history = useHistory();

  function setCartCountWithBadge(cart) {
    setCart(cart);
    setCartCount(cart.items.length);
  }

  function buyTickets() {
    if (cart.items.length == 0) return;
    if (currentUser == null) {
      Cookie.set('go_to_purchase', 'true', { path: '/', expires: 1 });
      return history.push(`/${project.name}/site/cpf`);
    }

    axios.post('/api/int/v1/purchase_orders')
      .then(function(res){
        setCartCount(0);
        history.push(`/${project.name}/site/pedido/${res.data.id}`);
      })
      .catch(function(error){
        if (error.response.status == 422 && error.response.data.cart_items) {
          toast.error(error.response.data.cart_items.join(', '));
        } else {
          toast.error('Erro ao comprar títulos, atualize a página por favor');
        }
      });
  }

  function loadShoppingCart() {
    axios.get('/api/int/v1/shopping_carts')
      .then(function(res){
        setCart(res.data.cart);
        setDefaultCampaign(res.data.default_campaign);
      })
      .catch(function(error){
        toast.error('Erro ao carregar carrinho de compras, atualize a página por favor');
      })
      .then(function(){
        setLoading(false);
      });
  }

  useEffect(() => {
    loadShoppingCart();
  }, []);

  return (
    <div>
      <PurchaseOrderSteps step={0} isMobile={isMobile} />
      <div className="container">
        {
          !loading && cart.items.length == 0 &&
            <div className="center" style={{paddingTop: 40}}>
              <h1>
                Seu carrinho está vazio
                <img src={SadIcon} style={{width: 30, marginLeft: 10, verticalAlign: 'middle'}} />
              </h1>
              <p>
                Participe do próximo sorteio, compre seus títulos clicando no botão agora:
              </p>
              <p>
                <Link to={`/${project.name}/site/compre-agora`}>
                  <Button size="medium" shape="round" type="primary">
                    Compre Agora
                  </Button>
                </Link>
              </p>
            </div>
        }
        {
          loading &&
            <div style={{textAlign: 'center', paddingTop: 120}}>
              <Spin size="large" />
            </div>
        }
        {
          !loading && cart.items.length > 0 &&
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CartItems cart={cart} setCart={setCartCountWithBadge} />
                {
                  defaultCampaign &&
                  <CampaignSmallBuy campaign={defaultCampaign} setCart={setCart} setCartCount={setCartCount} />
                }
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CartDetails cart={cart} buyTickets={buyTickets} />
              </Col>
            </Row>   
        }
      </div>
    </div>
  );
}